<template>
    <div class=" bg-white dark:bg-gray-800 xl:right-0 min-h-full xl:w-9/12 absolute h-20 w-full">

            <div class="container px:6 lg:16 mx-auto xl:pt-5 2xl:pt-12 relative h-screen">

            <div class="flex flex-wrap -m-1 md:m-8 text-center xl:m-10 xl:top-20 2xl:top-56 top-44 absolute">
                    <div class="xl:hidden block">
                        <div class="text-cyan-600 dark:text-lime-400 xl:px-20 lg:px-16 md:px-16 px-8 container mx-auto p-10 pt-2 xl:pb-10 
                        md:pb-10 lg:pb-10 2xl:pb-10 pb-5 text-left text-3xl font-medium animate__animated animate__bounce animate__delay-2s animate__repeat-1">
                            HI, I'm Keng Boon.
                        </div>
                        <div class=" text-base font-base text-gray-100 xl:px-20 lg:px-16 md:px-16 px-8 animate__animated animate__fadeInDown  animate__delay-1s text-justify">
                           Welcome to my personal website. I am a fresh graduate software engineer currently residing in Singapore.
                                 I'm hoping that my personal website will provide a quick overview of who I am.</div>

                        <div class="xl:px-20 lg:px-16 md:px-16 px-8 text-right mt-10">
                            <router-link tag="button" to="/about" class="text-lime-400 border-lime-400 border-2  px-3 py-1 border-r-2 mt-5 rounded-lg transform transition duration-500 hover:scale-125 
                            hover:shadow-lg">Learn More</router-link>
                        </div>
                    </div>

                    <div class="hidden xl:block xl:w-4/5 w-max">
                        <div class="w-full">
                            <div class="text-cyan-600 dark:text-lime-400 xl:px-20 lg:px-16 md:px-16 px-8 container mx-auto p-10 xl:pb-3 
                            lg:pb-16 2xl:pb-3 pb-10 text-left text-2xl xl:text-5xl lg:text-3xl 2xl:text-6xl font-medium animate__animated animate__bounce animate__delay-2s animate__repeat-1">
                                HI, I'm Keng Boon.
                                <!-- <hr id="line" class=" -m-1 mx-1"> -->
                            </div>

                            <div class=" text-lg font-medium text-gray-100 xl:px-20 lg:px-16 md:px-16 px-8 animate__animated animate__fadeInDown  animate__delay-1s text-justify">
                                Welcome to my personal website. I am a fresh graduate software engineer currently residing in Singapore.
                                 I'm hoping that my personal website will provide a quick overview of who I am.
                            </div>

                            <div class="xl:px-20 lg:px-16 md:px-16 px-8 text-right mt-10">
                                <router-link tag="button" to="/about" class="text-lime-400 border-lime-400 border-2  px-3 py-1 border-r-2 mt-5 rounded-lg transform transition duration-500 hover:scale-125 
                                hover:shadow-lg">Learn More</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="hidden xl:block xl:w-1/5 w-max">
                        <div class="w-full">
                        </div>
                    </div>
            </div>
        </div>

    </div>
</template>


<script>

import $ from 'jquery'

export default {
    name: 'About',
    props: {
    },
    components: {
    },
    methods:{
         hamburger:function(){
            const nav_menu = document.querySelector(".nav_menu");
            const menu_btn = document.querySelector(".hamburger");
            menu_btn.classList.toggle('is-active');
            nav_menu.classList.toggle('is-active');
        },
        direct:function(item){
            if(item !="")
            {
                window.location.href=item
            }
        }
    }
}


// $('.animate_line')
//   .delay(3000)
//   .queue(function (next) { 
//     $('.animate_line').css('width', '100% !important'); 
//     $("#line").addClass("animate_line_full");
//     alert("HI");
//     next(); 
//   });


 $("#line").addClass("animate_line_full");
// $('.animate_line').delay(2000).css('width', '100% !important'); 

// window.addEventListener("load", () => {
//  document.querySelector("body").classList.add("loaded"); 
// });

</script>

<style>

.animate_line {
    background:#c3c;
    width:100%;
    overflow:hidden;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 2s ease-in-out;
    transition-delay: 0s;
    height: 2px;
    animation: underline 4s ;
    animation-delay: 0s;
}

.animate_line_full {
 width: 100%;
}

@keyframes underline {
  from {width: 0%;}
  to {width: 100%;}
}

</style>