<template>
  <div class="md:flex min-h-full relative overflow-y-auto overflow-x-hidden pb-10 dark:bg-gray-800" style="height:100vh">
      <Content/>
      <Sidebar/>
      <Experience/>
      <Navbar/>
      <button class="hamburger absolute right-0 my-10 mr-8 sm:mr-16 md:mr-28 lg:mr-28 xl:mr-44 2xl:mr-58 xl:hidden " v-on:click="hamburger" style="z-index:98">
          <div class="bar"></div>
      </button>
      <router-view v-slot="{ Component}" class="hidden xl:block">
        <transition 
            enter-active-class="animate__animated animate__fadeInRight"
            leave-active-class="animate__animated animate__fadeOutRight"
            mode="out-in">
          <component :is="Component"/>
        </transition>
      </router-view>
       <router-view v-slot="{ Component}" class="block xl:hidden">
        <transition 
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
            mode="out-in">
          <component :is="Component"/>
        </transition>
        <!-- Test -->
      </router-view>

      <div id="progress" class="flex bg-gray-800">
        <div class=" m-auto justify-items-center grid">
           <img class="2xl:w-24 2xl:h-24  xl:w-20 xl:h-20 xl: md:w-16 md:h-16 w-14 h-14  mb-5 md:mb-10 mr-5 md:mr-8 xl:mr-10" :src="require('@/assets/logo.svg')" />
           <div class="text-lime-400 text-medium text-2xl  md:text-3xl xl:text-4xl 2xl:text-5xl animate__animated animate__pulse animate__infinite infinite">
             loading...</div>
        </div>
       
    </div>
  </div>
  

</template>

<script>
import Navbar from './components/Navbar.vue'
import Sidebar from './components/Sidebar.vue'
import $ from 'jquery'
// import { analytics } from '@/assets/firebaseDB';

// analytics.logEvent("visit_page");

export default {
  name: 'App',
  // router:router,
  components: {
    Navbar,
    Sidebar,

  },
   methods:{
        hamburger:function(){
            const nav_menu = document.querySelector(".nav_menu");
            const menu_btn = document.querySelector(".hamburger");
            menu_btn.classList.toggle('is-active');
            nav_menu.classList.toggle('is-active');
        }
    }

}

$(window).bind("load", function () {
        $('#progress').fadeOut(100);
});



</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  scrollbar-width: none;

  /* margin-top: 60px; */
}

#progress {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  z-index: 200000;
}


.fade-enter-from,
.fade-leave-to{
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active{
  transition: opacity 0.5s ease-out;
}

app::-webkit-scrollbar {
    display: none;
}

app::scrollbar {
    display: none;
}

app.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

.hamburger{
    position: relative;
    display: block;
    width: 35px;
    /* height: 35px; */
    appearance: none;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
}

.hamburger .bar , .hamburger::after, .hamburger::before{
    content: "";
    display: block;
    width: 100%;
    height: 4px;
     --tw-text-opacity: 1;
    background-color: rgba(163, 230, 53, var(--tw-text-opacity));
    margin: 6px 0px;
    transition: 0.4s;
    transition-duration:0.4s;
    -webkit-transition-duration:0.4s;
}

.hamburger.is-active::before{
    -webkit-transform: rotate(-45deg) translate(-9px,6px);
    transform: rotate(-45deg) translate(-9px,6px);
}

.hamburger.is-active::after{
  -webkit-transform: rotate(45deg) translate(-7px,-6px);
    transform: rotate(45deg) translate(-7px,-6px);
}

.hamburger.is-active .bar{
    opacity: 0;
}
</style>
