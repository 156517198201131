<template>
    <div class=" bg-white dark:bg-gray-800 xl:right-0 min-h-full xl:w-9/12 absolute h-20 w-full">

            <div class="container px:6 lg:16 mx-auto pt-20 sm:pt-10 xl:pt-8 2xl:pt-12">

            <div class="flex-wrap -m-1 md:m-8 text-center xl:m-10 hidden md:flex">
                <div class="p-8 xl:w-2/5 w- sm:w-2/5 w-full xl:max-w-xl">
                    <div class="px-4 relative 2xl:h-64 xl:h-52 md:h-52 h-44">
                        <div class="space-y-6 text-left pb-16">
                            <div>
                                <div class="text-lime-400 font-black text-2xl xl:text-4xl">
                                    {{Project.Name}}
                                </div>
                            </div>
                            <div>
                                <div class="text-white font-bold">
                                    Description
                                </div>
                                <div class="text-gray-200 text-sm pt-3 text-justify">
                                    {{Project.Description}}
                                </div>
                            </div>
                            <div>
                                 <div class="text-white font-bold">
                                    Category
                                </div>
                                <div class="text-gray-200 text-sm pt-3">
                                    {{Project.Category}}
                                </div>
                            </div>
                            <div>
                                <div class="text-white font-bold">
                                    Language(s) & Tool(s) used
                                </div>
                                <div class="text-gray-200 text-sm pt-3 text-justify">
                                    {{Project.Language}}
                                </div>
                            </div>
                            <div>
                                 <div class="text-white font-bold">
                                    Compatibility
                                </div>
                                <div class="text-gray-200 text-sm pt-3 flex space-x-3">
                                    <span v-if="Project.Compatibility == 1" ><img class="h-5 w-5" :src="require('@/assets/desktop.png')"/></span>
                                    <span v-if="Project.Compatibility == 2" ><img class="h-5 w-5" :src="require('@/assets/phone.png')"/></span>
                                    <span v-if="Project.Compatibility == 3" class="flex space-x-3">
                                        <img class="h-5 w-5" :src="require('@/assets/desktop.png')"/>
                                        <img class="h-5 w-5" :src="require('@/assets/phone.png')"/>
                                    </span>
                                </div>
                            </div>
                            <div class="flex flex-wrap">
                                <div class="mr-4">
                                    <button v-if="Project.Link != '' " class="text-lime-400 border-lime-400 border-2 px-8 py-2 border-r-2 mt-5 rounded-lg transform transition duration-500 hover:scale-105 
                                    hover:shadow-lg" v-on:click="direct(Project.Link)">Live Preview</button>
                                    <button v-if="Project.Link == '' " class="disabled:opacity-50 text-lime-400 border-lime-400 border-2 px-8 py-2 border-r-2 mt-5 rounded-lg transform transition duration-500 hover:scale-105 
                                    hover:shadow-lg cursor-not-allowed" disabled>Live Preview</button>
                                </div>
                                <div>
                                    <button v-if="SourceCode" class="text-lime-400 border-lime-400 border-2 px-8 py-2 border-r-2 mt-5 rounded-lg transform transition duration-500 hover:scale-105 
                                    hover:shadow-lg" v-on:click="direct(Project.Source_Code)">Source Code</button>
                                    <button v-if="!Project.Source_Code" class="disabled:opacity-50 text-lime-400 border-lime-400 border-2 px-8 py-2 border-r-2 mt-5 rounded-lg transform transition duration-500 hover:scale-105 
                                    hover:shadow-lg cursor-not-allowed" disabled>Source Code</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-8 xl:w-3/5 w- sm:w-3/5 w-full xl:max-w-xl" v-on:click="direct('')">
                    <div class="bg-white border-2 border-gray-600 dark:border-gray-800  px-4 py-6 rounded-lg transform transition duration-500 scale-105 shadow-2xl relative 2xl:h-64 xl:h-52 md:h-52 h-44" 
                   :style="{ backgroundImage: 'linear-gradient(to bottom, rgba(88,88,88,0) 40%,rgba(38,38,38,1)), url(' + Image + ')', backgroundRepeat:'no-repeat',backgroundSize:'cover' }">
                </div>
                </div>
            </div>

            <div class="flex flex-wrap -m-1 md:m-8 text-center xl:m-10  md:hidden">
                <div class="p-8 xl:w-3/5 w- sm:w-3/5 w-full xl:max-w-xl" v-on:click="direct('')">
                    <div class="bg-white border-2 border-gray-600 dark:border-gray-800  px-4 py-6 rounded-lg transform transition duration-500 scale-105 shadow-2xl relative 2xl:h-64 xl:h-52 md:h-52 h-44" 
                     :style="{ backgroundImage: 'linear-gradient(to bottom, rgba(88,88,88,0) 40%,rgba(38,38,38,1)), url(' + Image + ')', backgroundRepeat:'no-repeat',backgroundSize:'cover' }">
                </div>
                </div>

                <div class="p-8 xl:w-2/5 w- sm:w-2/5 w-full -mt-6 xl:max-w-xl">
                    <div class=" relative 2xl:h-64 xl:h-52 md:h-52 h-44">
                          <div class="space-y-3 text-left pb-16">
                            <div>
                                <div class="text-lime-400 font-black text-2xl xl:text-4xl">
                                   {{Project.Name}}
                                </div>
                            </div>
                            <div>
                                 <div class="text-white font-bold">
                                    Description
                                </div>
                                <div class="text-gray-200 text-sm pt-1 text-justify">
                                    {{Project.Description}}
                                </div>
                            </div>
                            <div>
                                 <div class="text-white font-bold">
                                    Category
                                </div>
                                <div class="text-gray-200 text-sm pt-1">
                                    {{Project.Category}}
                                </div>
                            </div>
                            <div>
                            <div class="text-white font-bold text-justify">
                                    Language(s) & Tool(s) used
                                </div>
                                <div class="text-gray-200 text-sm pt-1">
                                    {{Project.Language}}
                                </div>
                            </div>
                            <div>
                                 <div class="text-white font-bold">
                                    Compatibility
                                </div>
                                <div class="text-gray-200 text-sm pt-1 flex space-x-3">
                                    <span v-if="Project.Compatibility == 1" ><img class="h-5 w-5" :src="require('@/assets/desktop.png')"/></span>
                                    <span v-if="Project.Compatibility == 2" ><img class="h-5 w-5" :src="require('@/assets/phone.png')"/></span>
                                    <span v-if="Project.Compatibility == 3" class="flex space-x-3">
                                        <img class="h-5 w-5" :src="require('@/assets/desktop.png')"/>
                                        <img class="h-5 w-5" :src="require('@/assets/phone.png')"/>
                                    </span>
                                    
                                </div>
                            </div>
                            <div class="flex flex-wrap">
                                <div class="mr-4">
                                    <button v-if="Project.Link != '' " class="text-lime-400 border-lime-400 border-2 px-4 py-1 border-r-2 mt-5 rounded-lg transform transition duration-500 hover:scale-105 
                                    hover:shadow-lg" v-on:click="direct(Project.Link)">Live Preview</button>

                                    <button v-if="Project.Link == '' " class="disabled:opacity-50 cursor-not-allowed text-lime-400 border-lime-400 border-2 px-4 py-1 border-r-2 mt-5 rounded-lg transform transition duration-500 hover:scale-105 
                                    hover:shadow-lg" disabled>Live Preview</button>
                                </div>
                                <div>
                                    <button v-if="Project.Source_Code" class="text-lime-400 border-lime-400 border-2 px-4 py-1 border-r-2 mt-5 rounded-lg transform transition duration-500 hover:scale-105 
                                    hover:shadow-lg" v-on:click="direct(Project.Source_Code)">Source Code</button>
                                    <button v-if="!Project.Source_Code" class="disabled:opacity-50 cursor-not-allowed text-lime-400 border-lime-400 border-2 px-4 py-1 border-r-2 mt-5 rounded-lg transform transition duration-500 hover:scale-105 
                                    hover:shadow-lg" disabled>Source Code</button>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>

import { db } from '../firebase';
import $ from 'jquery'

export default {
    data(){
        return{
            Project: {},
            Image:{},
        }
    },
    name: 'Content',
    props: {
    },
    components: {
    },
    mounted(){
        let dbRef = db.collection('Projects').doc(this.$route.params.id);
        dbRef.get().then((doc) => {
                this.Project = doc.data();
                this.Image = doc.data().Image;
                this.Image = require('@/assets/' + this.Image);
                this.SourceCode = doc.data().Source_Code != "" ? doc.data().Source_Code : null;
            }).catch((error) => {
                console.log(error)
            })
    },
    methods:{
         hamburger:function(){
            const nav_menu = document.querySelector(".nav_menu");
            const menu_btn = document.querySelector(".hamburger");
            menu_btn.classList.toggle('is-active');
            nav_menu.classList.toggle('is-active');
        },
        direct:function(item){
            if(item !="")
            {
                window.location.href=item
            }
        },
        computeBackground() {
            // let bgImage = require('@/assets/' + src)
            // let link = "linear-gradient(to bottom, rgba(88,88,88,0) 40%,rgba(38,38,38,1)), url("+ bgImage +")`"
            return {
                backgroundImage: `linear-gradient(to bottom, rgba(88,88,88,0) 40%,rgba(38,38,38,1)), url(${this.Image})`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `cover`,
            }
        },
    }
}

$(window).bind("load", function () {
        $('#work-in-progress').fadeOut(100);
});



</script>

<style>

</style>