<template>
    <div class=" bg-white dark:bg-gray-800 xl:right-0 min-h-full xl:w-9/12 absolute h-20 w-full">

            <div class="container px:6 lg:16 mx-auto xl:pt-5 2xl:pt-12">

            <div class="flex">
                <div class="text-cyan-600 dark:text-lime-400 xl:px-20 lg:px-16 md:px-16 px-8 container mx-auto p-10 xl:pb-0 2xl:pb-5 pb-3 text-left text-2xl lg:text-3xl 2xl:text-5xl font-medium">
                    Project.
                </div>

            </div>

            <div class="flex flex-wrap -m-1 md:m-8 text-center xl:m-10">

                <div v-for="project in Projects" :key="project.key" class="p-8 xl:w-1/2 sm:w-1/2 w-full xl:max-w-xl">
                    <router-link v-if="project.status == 'workable'" tag="div" :to="{name: '/project/content', params: { id: project.key }}">
                        <div class="bg-white border-2 border-gray-600 dark:border-gray-800  px-4 py-6 rounded-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl relative 2xl:h-64 xl:h-52 md:h-52 h-44" 
                            :style="computeBackground(project.image)">
                            <a v-if="project.status == 'construction'" class="absolute inset-0 z-10 bg-gray-200 dark:bg-gray-800 rounded-lg text-center flex flex-col items-center justify-center opacity-0 hover:opacity-90 bg-opacity-90 duration-300">
                                <h1 class="font-medium text-lg dark:text-lime-400 ">Under contruction.</h1>
                            </a>
                            <a v-else-if="project.status == 'demo'" class="absolute inset-0 z-10 bg-gray-200 dark:bg-gray-800 rounded-lg text-center flex flex-col items-center justify-center opacity-0 hover:opacity-90 bg-opacity-90 duration-300">
                                <h1 class="font-medium text-lg dark:text-lime-400 ">For demo purpose..</h1>
                            </a>
                        <p class="text-xl font-medium text-cyan-600 dark:text-lime-400 text-left px-3 py-5 absolute bottom-0">{{project.name}}</p>
                        </div>
                    </router-link>

                     <div v-if="project.status != 'workable'">
                        <div class="bg-white border-2 border-gray-600 dark:border-gray-800  px-4 py-6 rounded-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl relative 2xl:h-64 xl:h-52 md:h-52 h-44" 
                            :style="computeBackground(project.image)">
                            <a v-if="project.status == 'construction'" class="absolute inset-0 z-10 bg-gray-200 dark:bg-gray-800 rounded-lg text-center flex flex-col items-center justify-center opacity-0 hover:opacity-90 bg-opacity-90 duration-300">
                                <h1 class="font-medium text-lg dark:text-lime-400 ">Under contruction.</h1>
                            </a>
                            <a v-else-if="project.status == 'demo'" class="absolute inset-0 z-10 bg-gray-200 dark:bg-gray-800 rounded-lg text-center flex flex-col items-center justify-center opacity-0 hover:opacity-90 bg-opacity-90 duration-300">
                                <h1 class="font-medium text-lg dark:text-lime-400 ">For demo purpose..</h1>
                            </a>
                        <p class="text-xl font-medium text-cyan-600 dark:text-lime-400 text-left px-3 py-5 absolute bottom-0">{{project.name}}</p>
                        </div>
                    </div>
                </div>
                
<!-- 
                <div class="p-8 xl:w-1/2 sm:w-1/2 w-full xl:max-w-xl" v-on:click="direct('https://kelvingohkengboon.github.io/thenextdoor.github.io/')">
                    <div class="bg-white border-2 border-gray-600 dark:border-gray-800  px-4 py-6 rounded-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl relative 2xl:h-64 xl:h-52 md:h-52 h-44" 
                    :style="thenextdoor">

                    <p class="text-xl font-medium text-cyan-600  dark:text-lime-400 text-left px-3 py-5 absolute bottom-0">The Next Door</p>
                    </div>
                </div>
                <router-link tag="div" to="/project/content" class="p-8 xl:w-1/2 sm:w-1/2 w-full xl:max-w-xl" v-on:click="direct('')">
                    <div class="bg-white border-2 border-gray-600 dark:border-gray-800  px-4 py-6 rounded-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl relative 2xl:h-64 xl:h-52 md:h-52 h-44" 
                    :style="freddy">

                        <a class="absolute inset-0 z-10 bg-gray-200 dark:bg-gray-800 rounded-lg text-center flex flex-col items-center justify-center opacity-0 hover:opacity-90 bg-opacity-90 duration-300">
                            <h1 class="font-medium text-lg dark:text-lime-400 ">Under contruction.</h1>
                        </a>

                    <p class="text-xl font-medium text-cyan-600 dark:text-lime-400 text-left px-3 py-5 absolute bottom-0">Freddy Clothing Store</p>
                    </div>
                </router-link>
                <div class="p-8 xl:w-1/2 sm:w-1/2 w-full xl:max-w-xl" v-on:click="direct('')">
                    <div class="bg-white border-2 border-gray-600 dark:border-gray-800  px-4 py-6 rounded-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl relative 2xl:h-64 xl:h-52 md:h-52 h-44" 
                    :style="hype">
                        
                        <a class="absolute inset-0 z-10 bg-gray-200 dark:bg-gray-800  rounded-lg text-center flex flex-col items-center justify-center opacity-0 hover:opacity-90 bg-opacity-90 duration-300">
                            <h1 class="font-medium text-lg dark:text-lime-400">For demo purpose.</h1>
                        
                        </a>
                        
                    <p class="text-xl font-medium text-cyan-600 dark:text-lime-400 text-left px-3 py-5 absolute bottom-0">Hype Essential</p>
                    </div>
                </div>
                <div class="p-8 xl:w-1/2 sm:w-1/2 w-full xl:max-w-xl" v-on:click="direct('')">
                    <div class="bg-white border-2 border-gray-600 dark:border-gray-800 px-4 py-6 rounded-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl relative 2xl:h-64 xl:h-52 md:h-52 h-44" 
                    :style="working">

                        <a class="absolute inset-0 z-10 bg-gray-200 dark:bg-gray-800 rounded-lg text-center flex flex-col items-center justify-center opacity-0 hover:opacity-90 bg-opacity-90 duration-300">
                            <h1 class="font-medium text-lg dark:text-lime-400 ">For demo purpose.</h1>
                        
                        </a>

                    <p class="text-xl font-medium text-cyan-600 dark:text-lime-400 text-left px-3 py-5 absolute bottom-0">Co-Working</p>
                    </div>
                </div> -->
            </div>
        </div>

    </div>
</template>


<script>
import { db } from '../firebase';
import $ from 'jquery'

export default {
    data(){
        return{
            thenextdoor:{
                 backgroundImage: `linear-gradient(to bottom, rgba(88,88,88,0) 40%,rgba(38,38,38,1)), url(${require('@/assets/thenextdoor.jpeg')})`,
                 backgroundRepeat: `no-repeat`,
                 backgroundSize: `cover`,
            },
            freddy:{
                 backgroundImage: `linear-gradient(to bottom, rgba(88,88,88,0) 40%,rgba(38,38,38,1)), url(${require('@/assets/freddy.jpg')})`,
                 backgroundRepeat: `no-repeat`,
                 backgroundSize: `cover`,
            },
            hype:{
                 backgroundImage: `linear-gradient(to bottom, rgba(88,88,88,0) 40%,rgba(38,38,38,1)), url(${require('@/assets/hype_essential.jpg')})`,
                 backgroundRepeat: `no-repeat`,
                 backgroundSize: `cover`,
            },
            working:{
                 backgroundImage: `linear-gradient(to bottom, rgba(88,88,88,0) 40%,rgba(38,38,38,1)), url(${require('@/assets/co_working.jpg')})`,
                 backgroundRepeat: `no-repeat`,
                 backgroundSize: `cover`,
            },
            Projects: [],
        }
    },
    name: 'Project',
    props: {
    },
    created(){
        db.collection('Projects').onSnapshot((snapshotChange) => {
            this.Projects = [];
            snapshotChange.forEach((doc) => {
                this.Projects.push({
                    key: doc.id,
                    name: doc.data().Name,
                    category: doc.data().Category,
                    compatibility: doc.data().Compatibility,
                    description: doc.data().Description,
                    language: doc.data().Language,
                    link: doc.data().Link,
                    image:doc.data().Image,
                    status:doc.data().Status,
                })
            });

            // console.log(this.Projects);
        })
    },
    components: {
    },
    methods:{
         hamburger:function(){
            const nav_menu = document.querySelector(".nav_menu");
            const menu_btn = document.querySelector(".hamburger");
            menu_btn.classList.toggle('is-active');
            nav_menu.classList.toggle('is-active');
        },
        direct:function(item){
            if(item !="")
            {
                window.location.href=item
            }
        },
        computeBackground(src) {
            // let bgImage = require('@/assets/' + src)
            return {
                backgroundImage: `linear-gradient(to bottom, rgba(88,88,88,0) 40%,rgba(38,38,38,1)), url(${require("@/assets/" + src )})`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `cover`,
            }
        },
    },
}

$(window).bind("load", function () {
        $('#work-in-progress').fadeOut(100);
});



</script>

<style>

</style>