<template>
    <div class=" bg-gray-200 dark:bg-gray-900 xl:fixed xl:left-0 xl:h-full h-full xl:w-3/12 container xl:block hidden xl:pt-8 2xl:pt-14">
        <div class="my-auto">
          <figure class=" p-14 xl:px-0 md:p-8 2xl:pb-4 xl:pb-0">
            <img class="w-24 h-24 md:w-28 md:h-auto rounded-full mx-auto" :src="require('@/assets/gkb.jpg')" width="200" height="200">
            <div class="pt-3 md:p-4 md:pb-0 text-center md:text-bottom space-y-6 md:space-y-3">

                <div class="space-y-2 md:space-y-2">
                    <div class="text-cyan-600 dark:text-gray-200 font-medium">
                        Kelvin, Goh Keng Boon
                    </div>
                    <div class="text-gray-500 dark:text-white dark:text-opacity-60">
                        Software Engineer, Singapore
                    </div>
                </div>

                <div class="rounded-lg inline-block flex-col items-center w-full p-2" style="overflow-wrap: anywhere;">
                    <ul class="list-reset justify-between space-y-1 md:space-y-1">
                        <li class="mr-3 flex flex-col">
                            <i class="fa fa-linkedin  text-sm md:text-lg bg-lime-400 hidden">
                                 <a class="font-semibold text-gray-400 text-xs md:text-base no-underline dark:hover:text-lime-400 py-2 px-4 transition duration-500 ease-in-out" v-on:click="direct('https://www.linkedin.com/in/kengboongoh/')" href="#">kengboongoh</a>
                            </i>

                            <div class="grid">
                              <i class="fa fa-linkedin  text-sm xl:text-xl text-lime-400"></i>
                              <a class="font-semibold text-gray-400 text-xs md:text-base no-underline dark:hover:text-lime-400 py-2 px-4 transition duration-500 ease-in-out" v-on:click="direct('https://www.linkedin.com/in/kengboongoh/')" href="#">kengboongoh</a>
                            </div>
                            <!-- <img class="w-2 h-2 md:w-5 md:h-auto rounded-full mx-auto pr-2" :src="require('@/assets/logo.png')" width="50" height="50"> -->
                           
                        </li>
                        <li class="mr-3 flex flex-col">

                            <i class="fa fa-github  text-sm md:text-lg hidden">
                                 <a class="font-semibold text-gray-400 text-xs md:text-base no-underline hover:text-red-400 py-2 px-4 transition duration-500 ease-in-out" v-on:click="direct('https://github.com/KelvinGKB')"  href="#">KelvinGKB</a>
                            </i>

                            <div class="grid">
                              <i class="fa fa-github  text-xl xl:text-2xl text-lime-400"></i>
                              <a class="font-semibold text-gray-400 text-xs md:text-base no-underline dark:hover:text-lime-400  py-2 px-4 transition duration-500 ease-in-out" v-on:click="direct('https://github.com/KelvinGKB')"  href="#">KelvinGKB</a>
                            </div>

                            <!-- <img class="w-2 h-2 md:w-5 md:h-auto rounded-full mx-auto pr-2" :src="require('@/assets/logo.png')" width="50" height="50"> -->
                    
                        </li>

                        <li class="mr-3 flex flex-col">

                            <div class="grid">
                              <i class="fa fa-envelope  text-xl md:text-xl text-lime-400"></i>
                              <a class="font-semibold text-gray-400 text-xs md:text-base no-underline dark:hover:text-lime-400  py-2 px-4 transition duration-500 ease-in-out" href="mailto:kengboongoh@gmail.com">kengboongoh@gmail.com</a>
                            </div>

                            <!-- <img class="w-2 h-2 md:w-5 md:h-auto rounded-full mx-auto pr-2" :src="require('@/assets/logo.png')" width="50" height="50"> -->
                    
                        </li>
                    </ul>
                  </div>

            </div>
        </figure>

        <div class="block  pt-2" style="border-top: 1px solid rgba(75, 85, 99);">
                <div class="nav px-10">
                    <div class="nav_box">
                        <router-link class="link text-cyan-600 dark:text-white font-semibold dark:hover:bg-lime-500 dark:bg-gray-900 bg-gray-300 hover:bg-gray-500 transition duration-500 ease-in-out rounded-lg" to="/about">About</router-link>
                    </div>
                    <div class="nav_box">
                        <router-link class="link text-cyan-600 dark:text-white font-semibold dark:hover:bg-lime-500 dark:bg-gray-900 bg-gray-300 hover:bg-gray-500 transition duration-500 ease-in-out rounded-lg" to="/project">Project</router-link>
                    </div>
            </div>
        </div>
        </div>
    </div>
</template>


<script>


export default {
 name: 'Sidebar',
 props: {
    links: [String]
 },
 components: {
 },
 methods:{
    direct:function(item){
        if(item !="")
        {
            window.location.href=item
        }
    }
 }
}

</script>

<style>



@media only screen and (min-width: 1280px) {
    a.router-link-active{
        --tw-bg-opacity: 1 !important;
        background-color: rgba(163, 230, 53, var(--tw-bg-opacity)) !important;
        color: rgba(34,34,34, var(--tw-bg-opacity)) !important;
        border-radius: 0.5rem/* 8px */;
    }
}

@media only screen and (max-width: 1279px) {
    a.router-link-active{
        --tw-bg-opacity: 1 !important;
        /* background-color: rgba(132, 204, 22, var(--tw-bg-opacity)) !important; */
        color: rgba(163, 230, 53, var(--tw-bg-opacity)) !important;
        border-radius: 0.5rem/* 8px */;
    }
}


</style>