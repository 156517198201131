<template>
    <div class=" bg-white dark:bg-gray-800 xl:right-0 min-h-full xl:w-9/12 absolute h-20 w-full">

            <div class="container px:6 lg:16 mx-auto xl:pt-5 2xl:pt-12 relative h-screen">

            <div class="flex flex-wrap -m-1 md:m-8 text-center xl:m-10 xl:top-20 2xl:top-56 top-24 absolute">
                    <div class="xl:hidden block">
                        <div class="text-cyan-600 dark:text-lime-400 xl:px-20 lg:px-16 md:px-16 px-8 container mx-auto p-10 pt-2 xl:pb-10 
                        md:pb-10 lg:pb-10 2xl:pb-10 pb-5 text-left text-3xl font-medium animate__animated animate__bounce animate__delay-2s animate__repeat-1">
                            About.
                        </div>

                        <div class=" text-base font-base text-gray-100 xl:px-20 lg:px-16 md:px-16 px-8  animate__animated animate__fadeInDown animate__delay-1s text-justify">
                            "I am fresh graduate software engineer with multiple work and internship experience who graduated from Tunku Abdul Rahman University College,TARUC. 
                            I am looking for <span class="text-red-400">job opportunity</span> in Singapore <br><br> I am experienced in building <span class="text-lime-400"> Company In-House React (Typescript) UI Component and Framework </span> to increase the reusability of the UI components 
                            and facilitate the productivity of the development of webpages. 
                            I am experienced in CSS frameworks such as <span class="text-lime-400">Tailwind, Ant Design and Bootstrap</span>, front-end frameworks such as <span class="text-lime-400">Vue.Js, Angular.Js</span> and Back-end Frameworks, <span class="text-lime-400">Java Springboot, PHP Laravel Framework, and C# ASP.NET MVC</span>. 
                            Apart from web development, I have experience developing mobile applications for Android using <span class="text-lime-400">Kotlin and Flutter</span>."
                        </div>
                        <div class="text-right text-lg font-semibold text-lime-500 xl:px-20 lg:px-16 md:px-16 px-8 py-8 animate__animated animate__pulse animate__delay-1s">
                            Kelvin, Goh Keng Boon
                        </div>
                    </div>

                    <div class="hidden xl:block">
                        <div class="text-cyan-600 dark:text-lime-400 xl:px-20 lg:px-16 md:px-16 px-8 container mx-auto p-10 xl:pb-3 
                        lg:pb-16 2xl:pb-3 pb-10 text-left text-2xl xl:text-5xl lg:text-3xl 2xl:text-6xl font-medium animate__animated animate__bounce animate__delay-2s animate__repeat-1">
                             About.
                        </div>

                        <div class=" text-base font-base text-gray-100 xl:px-20 lg:px-16 md:px-16 px-8  animate__animated animate__fadeInDown animate__delay-1s text-justify">
                            "I am fresh graduate software engineer with multiple work and internship experience who graduated from Tunku Abdul Rahman University College,TARUC. 
                            I am looking for <span class="text-red-400">job opportunity</span> in Singapore <br><br> I am experienced in building <span class="text-lime-400"> Company In-House React (Typescript) UI Component and Framework </span> to increase the reusability of the UI components 
                            and facilitate the productivity of the development of webpages. 
                            I am experienced in CSS frameworks such as <span class="text-lime-400">Tailwind, Ant Design and Bootstrap</span>, front-end frameworks such as <span class="text-lime-400">Vue.Js, Angular.Js</span> and Back-end Frameworks, <span class="text-lime-400">Java Springboot, PHP Laravel Framework, and C# ASP.NET MVC</span>. 
                            Apart from web development, I have experience developing mobile applications for Android using <span class="text-lime-400">Kotlin and Flutter</span>."
                        </div>

                        <div class="text-right text-lg font-semibold text-lime-500 xl:px-20 lg:px-16 md:px-16 px-8 pt-8 animate__animated animate__pulse animate__delay-1s">
                            Kelvin, Goh Keng Boon
                        </div>
                    </div>
            </div>
        </div>

    </div>
</template>


<script>

export default {
    name: 'About',
    props: {
    },
    components: {
    },
    methods:{
         hamburger:function(){
            const nav_menu = document.querySelector(".nav_menu");
            const menu_btn = document.querySelector(".hamburger");
            menu_btn.classList.toggle('is-active');
            nav_menu.classList.toggle('is-active');
        },
        direct:function(item){
            if(item !="")
            {
                window.location.href=item
            }
        }
    }
}


</script>

<style>

</style>