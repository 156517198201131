<template>
    <div class="nav_menu bg-gray-900 fixed container xl:hidden h-full overflow-x-hidden w:full min-width:full block" style="z-index:98;min-height:100vh;min-width:100%">

        <figure class=" p-14 pb-1 pt-20 xl:p-8 xl:h-full">
            <img class="w-24 h-24 xl:w-28 xl:h-auto rounded-full mx-auto" :src="require('@/assets/gkb.jpg')" width="200" height="200">
            <div class="pt-3 xl:p-8 text-center xl:text-bottom space-y-3 xl:space-y-8">

                <div class="space-y-2 xl:space-y-4">
                    <div class=" text-cyan-600 dark:text-gray-200 font-medium">
                        Goh Keng Boon
                    </div>
                    <div class="text-grat-300 dark:text-white dark:text-opacity-60">
                        Software Engineer, Malaysia
                    </div>
                </div>

                <div class="rounded-lg p-1 inline-block flex-col items-center w-full xl:p-4" style="overflow-wrap: anywhere;">

                     <ul class="list-reset justify-between space-y-2 xl:space-y-3">
                        <li class="mr-3 flex flex-col">
                            <i class="fa fa-linkedin  text-sm xl:text-lg hidden xl:block text-lime-400">
                                 <a class="font-semibold text-gray-400 text-xs xl:text-base no-underline hover:text-red-400 dark:hover:text-lime-400  py-2 px-4 transition duration-500 ease-in-out" v-on:click="direct('https://www.linkedin.com/in/kengboongoh/')" href="#">kengboongoh</a>
                            </i>

                            <div class="grid xl:hidden">
                              <i class="fa fa-linkedin  text-xl xl:text-lg text-lime-400"></i>
                              <a class="font-semibold text-gray-400 text-xs xl:text-base no-underline hover:text-red-400 dark:hover:text-lime-400  py-2 px-4 transition duration-500 ease-in-out" v-on:click="direct('https://www.linkedin.com/in/kengboongoh/')"  href="#">kengboongoh</a>
                            </div>
                            <!-- <img class="w-2 h-2 md:w-5 md:h-auto rounded-full mx-auto pr-2" :src="require('@/assets/logo.png')" width="50" height="50"> -->
                           
                        </li>
                        <!-- <li class="mr-3 flex flex-col">

                            <i class="fa fa-github  text-sm xl:text-lg hidden xl:block text-lime-400">
                                 <a class="font-semibold text-gray-400 text-xs xl:text-base no-underline hover:text-red-400 dark:hover:text-lime-400  py-2 px-4 transition duration-500 ease-in-out rounded-lg"  v-on:click="direct('https://github.com/KelvinGKB')"  href="#">KelvinGKB</a>
                            </i>

                            <div class="grid xl:hidden">
                              <i class="fa fa-github  text-2xl xl:text-lg text-lime-400"></i>
                              <a class="font-semibold text-gray-400 text-xs xl:text-base no-underline hover:text-red-400 dark:hover:text-lime-400  py-2 px-4 transition duration-500 ease-in-out rounded-lg" v-on:click="direct('https://github.com/KelvinGKB')"  href="#">KelvinGKB</a>
                            </div>

                            <img class="w-2 h-2 md:w-5 md:h-auto rounded-full mx-auto pr-2" :src="require('@/assets/logo.png')" width="50" height="50">
                    
                        </li> -->
                        <li class="mr-3 flex flex-col">

                            <i class="fa fa-envelope  text-sm xl:text-lg hidden xl:block text-lime-400">
                                 <a class="font-semibold text-gray-400 text-xs xl:text-base no-underline hover:text-red-400 dark:hover:text-lime-400  py-2 px-4 transition duration-500 ease-in-out rounded-lg" href="maito:kengboongoh@gmail.com">kengboongoh@gmail.com</a>
                            </i>

                            <div class="grid xl:hidden">
                              <i class="fa fa-envelope  text-lg xl:text-lg text-lime-400"></i>
                              <a class="font-semibold text-gray-400 text-xs xl:text-base no-underline hover:text-red-400 dark:hover:text-lime-400  py-2 px-4 transition duration-500 ease-in-out rounded-lg" href="mailto:kengboongoh@gmail.com">kengboongoh@gmail.com</a>
                            </div>

                            <!-- <img class="w-2 h-2 md:w-5 md:h-auto rounded-full mx-auto pr-2" :src="require('@/assets/logo.png')" width="50" height="50"> -->
                    
                        </li>
                    </ul>
                  </div>
            </div>
        </figure>

        <div class="block pt-2" style="border-top: 1px solid;">
                <div class="nav px-10">
                    <div class="nav_box" v-on:click="nav('about')">
                        <router-link class="link dark:text-white font-semibold hover:bg-lime-400 transition duration-500 ease-in-out rounded-lg" to="/about">About</router-link>
                    </div>
                    <div class="nav_box" v-on:click="nav('project')">
                        <router-link class="link dark:text-white font-semibold hover:bg-lime-400 transition duration-500 ease-in-out rounded-lg" to="/project">Project</router-link>
                    </div>
                </div>
        </div>
    </div>
</template>


<script>

export default {
 name: 'Navbar',
 props: {
 },
 components: {
 },
  methods:{
        hamburger:function(){
            const nav_menu = document.querySelector(".nav_menu");
            const menu_btn = document.querySelector(".hamburger");
            menu_btn.classList.toggle('is-active');
            nav_menu.classList.toggle('is-active');
        },
        nav:function(){
            const nav_menu = document.querySelector(".nav_menu");
            const menu_btn = document.querySelector(".hamburger");
            menu_btn.classList.toggle('is-active');
            nav_menu.classList.toggle('is-active');
        },
        direct:function(item){
        if(item !="")
        {
            window.location.href=item
        }
    }
    }
}

</script>

<style>

.nav_menu{
  left: 100%;
  transition: 0.4s;
}

.nav_menu.is-active{
  left: 0;
}

.nav a{
  display:block;
  width: 100%;
  margin: 0 auto 5px;
  text-align: center;
  padding: 6px 16px;
  text-decoration: none;
}

.nav .nav_box{
  padding: 5px 5px;
  border-bottom:1px solid rgba(75, 85, 99);
}

.animate__animated.animate__fadeIn, .animate__animated.animate__fadeOut {
  --animate-duration: 0.5s;
}

</style>