// import firebase from 'firebase/app';
// import 'firebase/firestore';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCuWAoTcOUvXn0i7hEHUrFD0_GSOcElAso",
    authDomain: "personal-website-ca6f2.firebaseapp.com",
    projectId: "personal-website-ca6f2",
    storageBucket: "personal-website-ca6f2.appspot.com",
    messagingSenderId: "729090685124",
    appId: "1:729090685124:web:0b531c235ecc9d9f857191",
    measurementId: "G-FBTDWZMJTF"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();
export const analytics = getAnalytics(firebaseApp);