import {createRouter,createWebHashHistory} from 'vue-router'

import Project from '@/components/Project.vue'
import About from '@/components/About.vue'
import Content from '@/components/Content.vue'
import Landing from '@/components/Landing.vue'

const router = createRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    history: createWebHashHistory(process.env.BASE_URL),
    routes:[
        {
            path: '/',
            component: Landing,
        },
        {
            path: '/about',
            component: About,
        },
        {
            path:'/project',
            component:Project,
        },
        {
            path:'/project/content/:id',
            name:'/project/content',
            component:Content,
        },
        {
            path:'/:pathMatch(.*)*',
            component: Landing,
        },
        { 
            path: '/:catchAll(.*)', 
            component: Landing,
            name: 'NotFound'
        }
    ],
})

export default router